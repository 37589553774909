import { useCurrencyStore } from '~/stores/currency';

export function useApiTops() {
  const { $api } = useNuxtApp();
  const { locale } = useI18n();

  const currencyStore = useCurrencyStore();

  const userCurrency = currencyStore.getCurrentCurrency;

  const defaultQuery = { currency: userCurrency?.code };

  const defaultHeaders = { 'Accept-Language': locale?.value };

  const defaultParams = {};

  const getTopCitiesWorldWide = async (propertyType) => {
    let result = ref(null);

    const callKey = `getTopCitiesWorldWide${locale?.value}${propertyType}${userCurrency?.code}`;
    const endpoint = `top/worldwide/cities/${propertyType}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    return result?.value?.data?.cities || null;
  };

  const getTopPropertiesWorldWide = async (propertyType) => {
    let result = ref(null);

    const callKey = `getTopPropertiesWorldWide${locale?.value}${propertyType}${userCurrency?.code}`;
    const endpoint = `top/worldwide/properties/${propertyType}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    return result?.value?.data?.properties || null;
  };

  const getTopPropertiesInLocation = async (propertyType, locationType, locationId) => {
    let result = ref(null);

    const callKey = `getTopPropertiesInPopularCities${locale?.value}${propertyType}${locationType}${locationId}${userCurrency?.code}`;
    const endpoint = `top/properties-in-popular-cities/${propertyType}/${locationType}/${locationId}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    const cities = result?.value?.data?.cities || [];

    return cities || null;
  };

  return {
    getTopCitiesWorldWide,
    getTopPropertiesWorldWide,
    getTopPropertiesInLocation,
  };
}
